<template>
  <div class="main-block">
    <div class="main-block-title">Personal info</div>
    <div class="main-block-content">

      <div class="person-block">
        <div class="grid">

          <form-group label="First Name" :value="form.first_name" :errors="errors.first_name">
            <input type="text" data-test="first_name" v-model="form.first_name" @change="validate(['first_name'])" class="form-control" autocomplete="given-name">
          </form-group>

          <form-group label="Last Name" :value="form.last_name" :errors="errors.last_name">
            <input type="text" data-test="last_name" v-model="form.last_name" @change="validate(['last_name'])" class="form-control" autocomplete="family-name">
          </form-group>

          <form-group label="Middle Name" :value="form.middle_name" :errors="errors.middle_name">
            <input type="text" data-test="middle_name" v-model="form.middle_name" @change="validate(['middle_name'])" class="form-control" autocomplete="additional-name">
          </form-group>
        </div>

        <div class="custom-hr"><span>Please enter your address</span></div>

        <div class="grid">
          <form-group label="Country" :value="form.country_id" :errors="errors.country_id">
            <v-select data-test="country_id" v-model="form.country_id" :reduce="option => option.id" :options="countriesSorted" @option:selected="validate(['country_id'])" label="label" class="form-control"></v-select>
          </form-group>

          <form-group label="City" :value="form.city" :errors="errors.city">
            <input v-model="form.city" data-test="city" type="text" @change="validate(['city'])" class="form-control" autocomplete="address-level2"/>
          </form-group>

          <form-group label="Street" :value="form.street" :errors="errors.street">
            <input v-model="form.street" data-test="street" type="text" @change="validate(['street'])" class="form-control" autocomplete="address-line1"/>
          </form-group>

          <div class="grid grid-template-3" style="margin-bottom: 0;">
            <form-group label="House" :value="form.house" :errors="errors.house">
              <input v-model="form.house" data-test="house" type="text" @change="validate(['house'])" class="form-control" autocomplete="address-line2"/>
            </form-group>

            <form-group label="Flat" :value="form.apartment" :errors="errors.apartment">
              <input v-model="form.apartment" data-test="apartment" type="text" @change="validate(['apartment'])" class="form-control" autocomplete="address-line3"/>
            </form-group>

            <form-group label="Postal Code" :value="form.postal_code" :errors="errors.postal_code">
              <input v-model="form.postal_code" data-test="postal_code" type="text" @change="validate(['postal_code'])" class="form-control" autocomplete="postal-code"/>
            </form-group>
          </div>
        </div>

        <div class="custom-hr"><span>Please add the required documents</span></div>

        <template v-if="!isVerified">
          <file-upload
              title="Identity Document"
              description="For passports issued inside the EEA or UK, please provide the necessary documentation."
              data-test="id_document_files"
              v-model="form.id_document_files"
              :types="walletTypeList"
              @change="validate(['id_document_files'])"
              name="id_document_files"
              :errors="errors"
          />

          <hr class="custom-hr">
        </template>

        <file-upload
            title="Additional Documents"
            description="For passports issued outside the EEA or UK, please provide the necessary documentation."
            data-test="residence_permit_files"
            v-model="form.residence_permit_files"
            :types="residencePermitTypeList"
            @change="validate(['residence_permit_files'])"
            name="residence_permit_files"
            :errors="errors"
            :min-size="0"
        />

        <hr class="custom-hr">

        <file-upload
            title="Address Confirmation"
            description="Please add files for proof of your address. It can be Utility Bill or Bank Statement."
            data-test="address_files"
            v-model="form.address_confirmation_files"
            :types="addressTypeList"
            @change="validate(['address_confirmation_files'])"
            name="address_confirmation_files"
            :errors="errors"
        />

        <hr>

        <div class="main-block-title">PEP/FATCA &nbsp; <icon-info color="#19191C" size="15" @click.native="openModal"/></div>

        <div class="grid">
          <div>
            <form-group label="Does the Person qualify as a PEP?" :value="form.is_pep" :errors="errors.is_pep">
              <label data-test="is_pep" class="form-control-custom" v-for="item in boolAnswers" :key="item.id">
                <input type="radio" v-model="form.is_pep" :value="item.id" @change="validate(['is_pep'])">
                <span class="form-control">{{ item.label }}</span>
              </label>
            </form-group>
          </div>

          <div>
            <form-group label="Does the Person qualify as a U.S. person?" :value="form.is_us" :errors="errors.is_us">
              <label data-test="is_us" class="form-control-custom" v-for="item in boolAnswers" :key="item.id">
                <input type="radio" v-model="form.is_us" :value="item.id" @change="validate(['is_us'])">
                <span class="form-control">{{ item.label }}</span>
              </label>
            </form-group>
          </div>
        </div>

        <transition name="fade">
          <pep-fatcha-modal v-show="modal" v-on:close="closeModal"/>
        </transition>
      </div>

      <div class="form-buttons text-right">
        <button type="submit" class="btn-next" @click="save" data-test="submit">
          <span>Save and proceed</span>
          <icon-next color="#19191C" size="15"/>
        </button>
      </div>

    </div>
  </div>
</template>
<script>
import IconNext from "@/assets/icons/IconNext";
import FormGroup from "@/components/FormGroup";
import FileUpload from "@/components/FileUpload.vue";
import IconInfo from "@/assets/icons/IconInfo";
import PepFatchaModal from "@/views/modals/PepFatcaModal";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'ContactsInfo',
  components: {
    FileUpload,
    IconNext,
    IconInfo,
    FormGroup,
    PepFatchaModal
  },
  data () {
    return {
      modal: false,

      form: {
        middle_name: null,
        last_name: null,
        first_name: null,
        is_pep: '',
        is_us: '',
        country_id: null,
        city: null,
        street: null,
        postal_code: null,
        house: null,
        apartment: null,
        address_confirmation_files: [],
        residence_permit_files: [],
        id_document_files: []
      },

      errors: {}
    }
  },
  mounted () {
    const data = this.getRegistrationDataPart('company-individual').attributes

    if (data) {
      this.form = data
    }
  },
  computed: {
    ...mapState({
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data,

      // Default list data
      addressTypeList: state => state.init.companyIndividualAddressConfirmationFileType,
      residencePermitTypeList: state => state.init.companyIndividualResidencePermitFileType,
      boolAnswers: state => state.init.boolAnswers,
      typeList: state => state.init.memberTypes,
      countries: state => state.countries.list,
      walletTypeList: state => state.init.companyIndividualIDDocumentFileType,
    }),
    ...mapGetters({
      countriesSorted: 'listSorted',
      getRegistrationDataPart: 'getRegistrationDataPart'
    }),
    isVerified () {
      return this.registrationData.verification.isCompleted
          && this.registrationData.verification.attributes.token
    },
    formData () {
      return {
        middle_name: this.form.middle_name,
        last_name: this.form.last_name,
        first_name: this.form.first_name,
        email: this.form.email,
        phone: this.form.phone,
        is_pep: this.form.is_pep,
        is_us: this.form.is_us,
        country_id: this.form.country_id,
        city: this.form.city,
        street: this.form.street,
        postal_code: this.form.postal_code,
        house: this.form.house,
        apartment: this.form.apartment,
        address_confirmation_files: this.form.address_confirmation_files,
        residence_permit_files: this.form.residence_permit_files,
        id_document_files: this.form.id_document_files
      }
    }
  },
  methods: {
    openModal () {
      this.modal = true
    },
    closeModal () {
      this.modal = false
    },
    validate (attributes = []) {

      for (let name of ['address_confirmation_files', 'residence_permit_files', 'id_document_files']) {
        let filesIndex = attributes.indexOf(name)
        if (filesIndex !== -1) {
          for (let i in this.formData[name]) {
            attributes.push(name + '[' + i + '][type]')
            attributes.push(name + '[' + i + '][name]')
            attributes.push(name + '[' + i + '][original_name]')
          }
        }
      }

      return this.$sdk.registration.profile.update(this.registrationHash, this.formData, 1).then(response => {
        for (const attr of attributes) {
          if (response.data[attr] !== undefined) {
            this.$set(this.errors, attr, response.data[attr])
          } else if (this.errors[attr] !== undefined) {
            this.$delete(this.errors, attr)
          }
        }
      })
    },
    save () {
      return this.$sdk.registration.profile.update(this.registrationHash, this.formData, 0).then(response => {
        if (Array.isArray(response.data) && response.data.length === 0) {
          this.$store.dispatch('updateRegistration').then(() => {
            this.$emit('next-step')
          })
        } else {
          this.errors = response.data
        }
      })
    }
  }
}
</script>


